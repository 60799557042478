import {isDefined, set} from "@vueuse/core";
import {useProgressIndicatorStore} from "~/stores/useProgressIndicatorStore";
import {useSitesStore} from "~/stores/useSitesStore";
import {EXO_CLICK_ID, HILLTOP, KADAM_ID, OCTO_CLICK, PUB_ADS_ID, TRAFFIC_STARTS_ID} from "~/const/ADVERTISER";

interface RuntimeConfig {
  public: {
    apiUrl: string;
  };
}
export const useAnalyticsStore = defineStore('analyticsStore', () => {
  const authStore = useAuthStore();
  const config: RuntimeConfig = useRuntimeConfig();
  const report: Ref<[] | null> = ref([]);
  const initialReport: Ref<[] | null> = ref([]);
  const shortInfo = ref({});
  const siteStore = useSitesStore();
  const { username,token } = authStore;

  const getFirstReport = computed(() => {
    const { Date, ...rest } = toValue(report)[0] ?? {};
    return rest;
  });

  const getTotalParameters = computed(() => toValue(report).reduce((acc, item) => {
    acc.impressions += (parseFloat(item.impressions) ?? 0);
    acc.profit += parseFloat(item.profit) || 0;
    acc.ecpm += parseFloat(item.ecpm) || 0;
    return acc;
  }, { impressions: 0, profit: 0, ecpm: 0 }));


  interface GenerateReportParams {
    range?: RangeKey;
    groupRange?: GroupRangeKey,
    groupBy?: [GroupBy] | GroupBy[''],
    country?: object,
    date_from?: string | null,
    date_to?: string | null,
    placement: string,
    adviser: string,
  }


  const generateReport = async ({
     date_from = null,
     country = {},
     date_to = null,
     placement = '',
     adviser = '',
                                  groupBy = '',
   }: GenerateReportParams) => {
    const progressStore = useProgressIndicatorStore();

    progressStore.updateStatus(true);
    let reportRef = null;
    switch (adviser) {
      case PUB_ADS_ID:
        reportRef = await getReportPubAds({
          date_from,
          country: country.code,
          placement,
          date_to,
        })
        break;
      case EXO_CLICK_ID:
        reportRef =  await getReportExoclick({
          date_from,
          date_to,
          country: country.iso3,
          placement,
        });
        break;
      case KADAM_ID:
        reportRef =  await getReportKadam({
          date_from,
          date_to,
          country: country.iso3,
          placement,
        });
        break;
      case OCTO_CLICK:
        reportRef = await getReportOctoClick({
          date_from,
          date_to,
          country: country.value,
          placement,
        });
        break;
      case HILLTOP:
        reportRef = await getReportHilltop({
          date_from,
          date_to,
          country: country.code,
          placement,
        });
        break;
      case TRAFFIC_STARTS_ID:
        reportRef = await getReportTrafficStarts({
          date_from,
          date_to,
          country: country.code,
          placement,
        });
        break;


      default:
        const data =  await getAllReports({
          date_from,
          date_to,
          countryCode: country.code,
          countryISO: country.iso3,
          countryValue: country.value,
        });

        reportRef =  groupBy.length ? data[1].value.value: data[0].value.value;
        break;
    }

    set(report, reportRef);
    progressStore.updateStatus(false);
  };

  async function getReportPubAds({
                                   date_from = null,
                                   date_to = null,
                                   country = '',
                                   placement = ''
                                 }) {
    if (toValue(siteStore.siteId) === null) {
      await siteStore.updateListSites();
    }
    return await $fetch('/api/adviser-api/pubads/get-stat', {
      query: {
        token: toValue(token),
        username: toValue(username),
        country,
        date_from,
        date_to,
        placement,
        siteId: toValue(siteStore.siteId),
    }});
  }

  async function getReportHilltop( {
                                    date_from = '',
                                     country = '',
                                    date_to = '',
                                     placement = ''
                                   } ) {

    return $fetch('/api/adviser-api/hilltop/get-stat', {
      query: {
        date_from,
        date_to,
        country,
      }
    })
  }

  async function getReportTrafficStarts({
                                     date_from = null,
                                     country = '',
                                     date_to = null,
                                     placement = ''
                                   }) {
    if (!isDefined(toValue(authStore.trafficStarsUser).access_token)) {
      await authStore.loginTrafficStars();
    }
    return $fetch('/api/adviser-api/traffic-stars/get-stat', {
      query: {
        token: toValue(authStore.trafficStarsUser).access_token,
        date_from,
        country,
        date_to
      }
    })
  }

  async function getReportExoclick({
                                     date_from = '',
                                     country = '',
                                     date_to = '',
                                     placement = ''
                                   }) {
    if (!isDefined(toValue(authStore.exoClickUser).token)) {
      await authStore.loginExoClick();
    }

    return $fetch('/api/adviser-api/exoclick/get-stat', {
      query: {
        country,
        date_from,
        date_to,
        token: toValue(authStore.exoClickUser).token
      }
    })
  }

  async function getReportOctoClick( {
                                    date_from = '',
                                    country = null,
                                    date_to = '',
                                    placement = ''
                                  } ) {

    return $fetch('/api/adviser-api/octoclick/get-stat', {
      query: {
        date_from,
        date_to,
        ...(isDefined(country) && {country})
      }
    })
  }

  async function getReportKadam( {
                                       date_from = '',
                                       country = null,
                                       date_to = '',
                                       placement = ''
                                     } ) {

    return $fetch('/api/adviser-api/kadam/get-stat', {
      query: {
        date_from,
        date_to,
        ...(isDefined(country) && {country})
      }
    })
  }

  async function getAllReports({
                                 date_from = '',
                                 date_to = '',
                                 countryCode= null,
                                 countryISO = null,
                                 countryValue =  null,
                               }) {
    if (!isDefined(toValue(authStore.trafficStarsUser).access_token)) {
      await authStore.loginTrafficStars();
    }
    if (!isDefined(toValue(authStore.exoClickUser).token)) {
      await authStore.loginExoClick();
    }
    if (toValue(siteStore.siteId) === null) {
      await siteStore.updateListSites();
    }

    return $fetch('/api/adviser-api/all/get-stat', {
      query: {
        date_from,
        date_to,
        username: toValue(username),
        tokenPubAds: toValue(token),
        tokenExo: toValue(authStore.exoClickUser).token,
        tokenTraffic: toValue(authStore.trafficStarsUser).access_token,
        siteId: toValue(siteStore.siteId),
        ...(isDefined(countryCode) && {countryCode}),
        ...(isDefined(countryISO) && {countryISO}),
        ...(isDefined(countryValue) && {countryValue})
      }
    })
  }

  function setShortInfo(info) {
    set(shortInfo, info);
  }

  return {
    report,
    initialReport,
    getFirstReport,
    generateReport,
    shortInfo,
    getReportPubAds,
    getReportHilltop,
    getReportExoclick,
    getReportOctoClick,
    getReportTrafficStarts,
    setShortInfo,
    getAllReports,
    getTotalParameters,
  }
})
